/*global $*/
/*eslint no-undef: "error"*/
export default class blockShow {
  constructor () {
    $('.block_show .show_button').on('click', function () {
      let textOpen = $(this).data('open')
      let textClose = $(this).data('close')

      if ($(this).closest('.block_show').hasClass('show')) {
        $(this).closest('.block_show').removeClass('show')
        $(this).text(textOpen)
      } else {
        $(this).closest('.block_show').addClass('show')
        $(this).text(textClose)
      }
    })
  }
}
