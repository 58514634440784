<template lang="pug">
.form
  .form__block(v-show="!sendForm")
    .form__title(v-html="formTitle")

    .form__fields
      .form__field
        input.input-text(
          type="text"
          :placeholder="formFieldNamePlaceholder"
          v-model="fieldName"
        )
      .form__field
        input.input-text(
          type="tel"
          :placeholder="formFieldPhonePlaceholder"
          v-model="fieldPhone"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )

    .form__consent
      .politics
        label.politics__wrapper
          input.politics__input(
            type="checkbox"
            value="consent"
            checked="checked"
            v-model="consent"
          )
          span.politics__label.politics__label-white Я согласен с <a href="/kon.pdf" target="_blank" class="politics__link">политикой конфиденциальности</a> персональных данных и даю свое согласие на их <a href="/obr.pdf" target="_blank" class="politics__link">обработку</a>.

    .form__button
      div.button(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      ) Отправить

  .form__block(v-show="sendForm")
    .form__title(v-html="formSuccessTitle")
    p.center(v-html="formSuccessText")

</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formTitle",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",

    "formSuccessTitle",
    "formSuccessText",

    "formConsent",

    "formFieldPage",
    "formPagePagetitle",
    "formPageId"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      files: '',
      consent: [],
      sendForm: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            number: '2',
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
          $('.is-close-btn').click()
        }, 3500)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
