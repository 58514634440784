/*global $*/
/*eslint no-undef: "error"*/
export default class ProductType {
  classBlock = 'products-type__block'
  classBlockActive = 'products-type__block-active'
  idProductsWrapper = 'products__table'
  classProductsSquare = 'products__table-square'

  constructor () {
    let self = this
    $(`.${self.classBlock}`).click(function () {
      const type = $(this).data('type')
      if (!$(this).hasClass(self.classBlockActive)) {
        $(`.${self.classBlock}`).removeClass(self.classBlockActive)
        $(this).addClass(self.classBlockActive)

        if (type === 'square') {
          $(`.${self.idProductsWrapper}`).addClass(self.classProductsSquare)
        } else {
          $(`.${self.idProductsWrapper}`).removeClass(self.classProductsSquare)
        }
      }
    })

    $(window).resize(() => {
      const windowWidth = window.innerWidth

      if (windowWidth <= 660) {
        $(`.${self.idProductsWrapper}`).removeClass(self.classProductsSquare)
        $(`.${self.classBlock}:eq(0)`).removeClass(self.classBlockActive)
        $(`.${self.classBlock}:eq(1)`).addClass(self.classBlockActive)
      }
    })
  }
}
