const $ = window.$ = window.jQuery = require('jquery')
require('bxslider/dist/jquery.bxslider')

/*eslint no-undef: "error"*/
export default class BXSlider {
  selectorPartners = 'partners__wrapper'
  selectorPartnersOptions = {
    minSlides: 6,
    maxSlides: 6,
    slideWidth: 170,
    slideMargin: 10,
    controls: false,
    touchEnabled: false
  }

  objProducts = {}
  selectorProducts = 'products__body-slider'
  selectorProductsOptions = {
    minSlides: 4,
    maxSlides: 4,
    slideWidth: 255,
    slideMargin: 10,
    controls: false,
    touchEnabled: false
  }
  initBxSliderOtzivi (selector, options = {}) {
    let objectBlock = $('.' + selector)
    return objectBlock.bxSlider(options)
  }

  // Изменяем кол-во объектов
  reloadBXSlider () {
    let windowWidth = window.innerWidth

    if ($(`.${this.selectorProducts}`).length) {
      if (windowWidth > 779) {
        this.selectorProductsOptions.minSlides = 4
        this.selectorProductsOptions.maxSlides = 4
        this.selectorProductsOptions.slideWidth = 255
        this.selectorProductsOptions.slideMargin = 10
      } else if ((windowWidth > 480) && (windowWidth <= 779)) {
        this.selectorProductsOptions.minSlides = 2
        this.selectorProductsOptions.maxSlides = 2
        this.selectorProductsOptions.slideWidth = 300
        this.selectorProductsOptions.slideMargin = 7
      } else {
        this.selectorProductsOptions.minSlides = 1
        this.selectorProductsOptions.maxSlides = 1
        this.selectorProductsOptions.slideWidth = 340
        this.selectorProductsOptions.slideMargin = 0
      }

      this.objProducts.reloadSlider(this.selectorProductsOptions)
    }
  }

  constructor () {
    this.initBxSliderOtzivi(this.selectorPartners, this.selectorPartnersOptions)
    this.objProducts = this.initBxSliderOtzivi(this.selectorProducts, this.selectorProductsOptions)

    $(window).resize(() => {
      this.reloadBXSlider()
    })
  }
}
