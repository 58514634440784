/*global $*/
/*eslint no-undef: "error"*/
export default class FixedMenu {
  constructor () {
    $(document).scroll(function () {
      let scrollY = window.pageYOffset

      if (scrollY >= 220) {
        $('.page-header-menu').addClass('fixed')
      } else {
        $('.page-header-menu').removeClass('fixed')
      }
    })
  }
}
