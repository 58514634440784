/*global $*/
/*eslint no-undef: "error"*/
export default class ProductProperty {
  selectBlock = 'product-property'
  selectElement = this.selectBlock + '__tab'
  objectElement = $('.' + this.selectElement)
  selectContent = this.selectBlock + '__content'
  objectContent = $('.' + this.selectBlock + '__content')
  constructor () {
    let selectElement = this.selectElement
    let objectElement = this.objectElement
    let selectContent = this.selectContent
    let objectContent = this.objectContent
    this.objectElement.click(function () {
      let tab = $(this).data('tab')
      $(this).toggleClass(selectElement + '-active')
      objectElement.not(`[data-tab=${tab}]`).removeClass(selectElement + '-active')
      objectContent.removeClass(selectContent + '-active')
      $(`.${selectContent}[data-content=${tab}]`).addClass(selectContent + '-active')
    })
  }
}
