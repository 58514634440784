/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import Inputmask from 'inputmask'
import { Fancybox } from '@fancyapps/ui'
import BXSlider from '@/assets/js/bxslider'
import ProductType from '@/assets/js/ProductType/ProductType'
import blockShow from '@/assets/js/blockShow'
import FixedMenu from '@/assets/js/fixedMenu'
import ProductProperty from '@/assets/js/ProductProperty'
import AppFormPhone from '@/components/Form/AppFormPhone.vue'
import AppFormOrder from '@/components/Form/AppFormOrder.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Inputmask('7 (999) 999-9999').mask('.input-text--phone')

    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    if(document.getElementById('block--form-phone') != null) {
        createApp({})
            .component('block-form', AppFormPhone)
            .use(Maska)
            .use(form)
            .mount('#block--form-phone')
    }

    if(document.getElementById('block--form-order') != null) {
        createApp({})
            .component('block-form-order', AppFormOrder)
            .use(Maska)
            .use(form)
            .mount('#block--form-order')
    }

    new BXSlider()
    new ProductType()
    new blockShow()
    new FixedMenu()
    new ProductProperty()
})
